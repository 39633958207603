import constantes from "@/utils/constantes";
import moment from "moment";
import XLSX from "xlsx";

function Utils() {}

// Define read-only property EMPTY_STRING
Object.defineProperty(Utils, "EMPTY_STRING", {
  value: "",
  writable: false,
  configurable: false
});

Utils.excelMimetype = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
Utils.pdfMimetype = "application/pdf";
Utils.zipMimetype = "application/zip";
Utils.gzipMimetype = "application/gz";
Utils.textMimetype = "text/plain";

Utils.universLabels = {}
Utils.universLabels[constantes.univers.commun] = "commun";
Utils.universLabels[constantes.univers.capital] = "capital";
Utils.universLabels[constantes.univers.participation] = "participation";
Utils.universLabels[constantes.univers.interessement] = "intéressement";


/**
 * Get the width of a scrollbar as it is generated by the client and does not have a standardized width.
 *
 * @return number : scrollbarWidth (pixels)
 */
Utils.getScrollbarWidth = function () {
  // Creating invisible container
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement('div');
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

  // Removing temporary elements from the DOM
  outer.parentNode.removeChild(outer);

  return scrollbarWidth;
}


/**
 * Programmatically open an "expandable panel" in an "expandable panels" with the "multiple" attribute.
 * If the panel is already opened, do nothing.
 *
 * @param {Array[Integer]} expandablePanelsModel
 * @param {Integer} expandablePanelIndex
 * @return void
 */
Utils.openExpandablePanel = function (expandablePanelsModel, expandablePanelIndex) {
  let index = expandablePanelsModel.indexOf(expandablePanelIndex);
  if (index === -1) {
    expandablePanelsModel.push(expandablePanelIndex);
  }
}

/**
 * Programmatically close an "expandable panel" in an "expandable panels" with the "multiple" attribute.
 * If the panel is already closed, do nothing.
 *
 * @param {Array[Integer]} expandablePanelsModel
 * @param {Integer} expandablePanelIndex
 * @return void
 */
Utils.closeExpandablePanel = function (expandablePanelsModel, expandablePanelIndex) {
  let index = expandablePanelsModel.indexOf(expandablePanelIndex);
  if (index !== -1) {
    expandablePanelsModel.splice(index, 1);
  }
}


/**
 * Format a number for display
 *
 * If number is en integer, no change
 * If number is other (aka float), it is 2 decimals formatted
 * @param {Number} number
 * @return {String} formatted number
 */
Utils.formatNumber = function (number, decimals_number=2, force_decimals=false) {
  if (_.isInteger(number) && !force_decimals) {
    return number.toString();
  } else {
    return _.toNumber(number).toFixed(decimals_number);
  }
};

/**
 * Format a number for display
 *
 * it is 2 decimals formatted
 * @param {Number} number
 * @param {digits} number
 * @return {Function} formatted number
 */
Utils.formatCurrency = function (digits = 2) {
  function currencyFormater(number) {
    return Number(number).toFixed(digits);
  }

  return currencyFormater;
};

/**
 * Return the rounded value with X digits precision
 * @param numberToRound
 * @param digits
 * @return {number}
 */
Utils.roundNumber = function (numberToRound, digits = 2) {
  let digitsValue = Math.pow(10, digits);
  return Math.round(numberToRound * digitsValue) / digitsValue;
};

/**
 * Return the rounded value with X digits precision, digits are separated by a comma ","
 * @param numberToRound
 * @param digits
 * @return {number}
 */
Utils.roundEuroFilter = function(valueToFormat, digits = 2) {
  let valueToReturn = "-";
  if (!_.isNil(valueToFormat)) {
    valueToReturn = Utils.formatInput(valueToFormat, Utils.formatCurrency(digits), "€");
    valueToReturn = Utils.remplacerPointParVirgule(valueToReturn);
    valueToReturn = Utils.separerMilierParEspace(valueToReturn);
  }
  return valueToReturn;
}

/**
 * Renvoie l'input formaté avec la fonction formatFunction et ajoute éventuellement un symbole à la fin séparé par un espace
 * Renvoie "-" si l'input n'est pas défini
 * @param input
 * @param {Function} formatFunction la fonction à utiliser pour formater l'input
 * @param {String} endChar optionnel, le caractère à ajouter à la fin
 * @returns {String}
 */
Utils.formatInput = function(input, formatFunction, endChar) {
  if (_.isNil(input)) {
    return "-";
  } else {
    return formatFunction(input) + (_.isNil(endChar) ? "" : "\u00A0" + endChar);
  }
}

/**
 * Formate le nom technique d'un univers vers son label
 * @param {String} univers 
 * @param {String} default_value 
 * @returns {String}
 */
Utils.formatUnivers = function(univers, default_value = "aucun") {
  if (_.isNil(univers)) {
    return default_value;
  }
  return Utils.universLabels[univers];
}
/**
 * Capitalizes the first letter of the provided string.
 *  @param {String} valueToCapitalize
 *  @returns {String} The string with the first letter capitalized, or the original value if it's `null`, `undefined` or `number`.
 */
Utils.capitalizeFirstLetter = function(valueToCapitalize) {
  if (!_.isNil(valueToCapitalize) && isNaN(valueToCapitalize)) {
    let firstCharacter = valueToCapitalize.charAt(0)
    // Check if firstCharacter is a letter and not a special character
    if (firstCharacter.match(/^[\p{L}]*$/u)) {
      return valueToCapitalize.charAt(0).toUpperCase() + valueToCapitalize.slice(1);
    }
     
  }
  return valueToCapitalize;
}

Utils.remplacerPointParVirgule = function(input) {
  return input.replace(/(\d)[.](\d)/g, '$1,$2');
}

Utils.separerMilierParEspace = function (input) {
  return input.replace(/(\d)(?=(\d{3})+\b)/g, '$1\u00A0');
};

/**
 * Transform {"key":"keyValue"} flat dictionnary sent by backend
 * into [{value: "key", text: "keyValue"}] array of objects used by v-select component
 * @param {Object} label
 * @return {Array} of Object with only two attributes value and text
 */
Utils.fromLabelToSelect = function (label) {
  let select = [];
  for (let key in label) {
    select.push({
      value: key,
      text: label[key]
    });
  }
  return select;
};

/**
 * Transforms a resource list of API resource objects into a usable
 * selectfield choice list
 *
 * @param {Array} resourceList
 * @param {String} labelAttr optionnal, name of the attribute to be displayed in select
 * @returns {Array}
 */
Utils.fromArrayToSelect = function (resourceList, labelAttr = "name") {
  return resourceList.map(item => {
    return {
      value: item.id,
      text: item[labelAttr]
    };
  });
};

Utils.fromArrayToSelectCategorieCapital = function (resourceList, labelAttr = "name") {
  return resourceList.map(item => {
    return {
      value: item.categorie_capital_id,
      text: item[labelAttr]
    };
  });
};

/**
 * Dans le contexte d'une liste de champs checkbox ayant un lien de validation entre eux,
 * permet d'obtenir la liste des valeurs des checkbox cochées à partir d'un objet JS.
 *
 * Les valeurs des checkbox sont définies via l'attribut "value" sur leur tag HTML "v-checkbox".
 * Les checkbox doivent toutes faire référence au même v-model, ce v-model peut être initialisé avec le retour
 * de cette fonction.
 *
 * @param {Object} obj: L'objet JS dont les propriétés sont des v-model de checkbox
 * @param {Array[String]} properties_to_include: optionnel, définir les propriétés de l'objet donné à traiter,
                                                 traite toutes les propriétés si non-défini
 * @returns {Array[String]}
 */
Utils.fromObjectToCheckboxList = function (obj, properties_to_include = null) {
  if (properties_to_include == null) {
    properties_to_include = Object.keys(obj);
  }

  let res = [];
  properties_to_include.forEach(property => {
    if (obj[property]) {
      res.push(property);
    }
  });

  return res;
}

/**
 * Dans le contexte d'une liste de champs checkbox ayant un lien de validation entre eux,
 * permet d'éditer un objet JS à partir de la liste des valeurs des checkbox cochées.
 *
 * Les valeurs des checkbox sont définies via l'attribut "value" sur leur tag HTML "v-checkbox".
 * Les checkbox doivent toutes faire référence au même v-model, ce v-model peut être initialisé avec le retour
 * de cette fonction.
 *
 * @param {Array[String]} checked_list: La liste des valeurs des checkbox cochées
 * @param {Object} obj: L'objet JS dont les propriétés sont des v-model de checkbox (sera altéré)
 * @param {Array[String]} properties_to_include: optionnel, définir les propriétés de l'objet donné à traiter,
                                                 traite toutes les propriétés si non-défini
 * @returns void
 *
 * @alters {Object} obj
 */
Utils.fromCheckboxListToObject = function (checked_list, obj, properties_to_include = null) {
  if (properties_to_include == null) {
    properties_to_include = Object.keys(obj);
  }

  properties_to_include.forEach(property => {
    obj[property] = checked_list.includes(property);
  });
}

/**
 * DATE Utils
 */

Utils.dateFRFormat = "DD/MM/YYYY";
Utils.dateFRFormatWithHour = "DD/MM/YYYY HH:mm";
Utils.dateIsoFormat = "YYYY-MM-DD";
Utils.onlyYearFormat = "YYYY";

/**
 * Change an ISO8610 date format (YYYY-MM-DD) into FR date format (DD/MM/YYYY)
 * @param {String} date in ISO8610 format
 * @return {String} A string of the same date with DD/MM/YYYY format
 */
Utils.formatDateIsoToFr = function (date) {
  if (!date) return null;
  return moment(date).format(Utils.dateFRFormat);
};

/**
 * Change an ISO8610 date format (YYYY-MM-DD) into JS date object
 * @param {String} date in ISO8610 format
 * @return {Date} JS Date object instance
 */
Utils.getJsDateFromStringIso = function(string_date) {
  return moment(string_date, Utils.dateIsoFormat);
}

/**
 * Change a JS date object into an ISO8610 date format (YYYY-MM-DD)
 * @param {Date} JS Date object instance
 * @return {String} date in ISO8610 format
 */
Utils.getStringIsoFromJsDate = function(date_obj) {
  return date_obj.format(Utils.dateIsoFormat);
}

/**
 * Add days, months or years to an ISO8610 date format (YYYY-MM-DD)
 * @param {String} date in ISO8610 format
 * @param {Number} Number of days to add
 * @param {Number} Number of months to add
 * @param {Number} Number of years to add
 * @return {String} An ISO8610 string of the new date (YYYY-MM-DD)
 */
Utils.addToIsoDate = function (date, nb_days=0, nb_months=0, nb_years=0) {
  if (_.isNil(date)) {
    return date;
  }
  const dateObj = Utils.getJsDateFromStringIso(date);
  dateObj.add(nb_days, "days");
  dateObj.add(nb_months, "months");
  dateObj.add(nb_years, "years");
  return Utils.getStringIsoFromJsDate(dateObj);
};

Utils.dateIsoToFrFilterWithHourMin = function (date) {
  if (!date) return null;
  return moment(date).format(Utils.dateFRFormatWithHour);
};

Utils.formatDateStringIsoToFr = function (date) {
  if (!date) return null;
  return moment(date, Utils.dateIsoFormat).format(Utils.dateFRFormat);
};


Utils.formatDateStringFrToIso = function (date) {
  if (!date) return null;
  const dateParsed = moment(date, Utils.dateFRFormat, true);
  if (dateParsed.isValid()) {
    return dateParsed.format(Utils.dateIsoFormat);
  }
  else {
    return false;
  }
};

/**
 * Extract the year of a date
 * @param {String} date in any format
 * @return {String} The year of the date in any format
 */
Utils.formatYearFromDate = function (date) {
  if (!date) return null;
  return moment(date).format(Utils.onlyYearFormat);
};

/**
 * Formatte un objet adresse :
 * adress1
 * adress2 (optionnel)
 * zip_code city
 * @param adresse
 * @return {default.props.address1|{type}}
 */
Utils.formatAddress = function (adresse) {
  if (!_.isNil(adresse) && !_.isNil(adresse.address1) && !_.isNil(adresse.zip_code) && !_.isNil(adresse.city)) {
    const newline = "<br/>";
    let numVoie = "";
    if (!_.isNil(adresse.numero_voie)) {
      numVoie = adresse.numero_voie + " ";
    }
    let type_voie = "";
    if (!_.isNil(adresse.type_voie)) {
      type_voie = adresse.type_voie + " ";
    }
    let formatedAddress = numVoie + type_voie + adresse.address1;
    if (!_.isNil(adresse.address2)) {
      formatedAddress += newline + adresse.address2;
    }
    formatedAddress += newline + adresse.zip_code + " " + adresse.city;
    return formatedAddress;
  } else {
    return "-";
  }
};

/**
 * Change an FR date format(DD / MM / YYYY) in ISO8610 date format(YYYY - MM - DD)
 * @param {String} date with DD/MM/YYYY format
 * @return {String} A string of the same date in ISO8610 format
 */
Utils.formatDateFrToIso = function (date) {
  if (!date) return null;
  return moment(str, Utils.dateFRFormat);
};

Utils.displayDateToIsoFormat = function displayDateToIsoFormat(date) {
  return moment(date).format(Utils.dateIsoFormat);
};

Utils.convertStringIsoToDate = function convertStringIsoToDate(dateString) {
  return moment(dateString, Utils.dateIsoFormat).toDate();
};

/**
 * Recursive method used to check if a list on components containing inputs
 * are valid using VeeValidate
 * @param {Array[Object]} components lists on components to check input validation
 * @param {Boolean} isValid current form validation status
 * @returns {Promise[Boolean]} asynchonous reponse: whether all inputs are valid or not
 */
Utils.formIsValid = function (components, isValid = true) {
  if (components.length === 0) {
    return isValid;
  } else {
    const elm = components.pop();
    return elm.$validator.validateAll().then(res => {
      return Utils.formIsValid(components, isValid && res);
    });
  }
};

/**
 * XSLX Utils
 */

/**
 * Generate Xlsx sheet containing exactly the same table that datatable
 * sorted by first column.
 *
 * WARNING : each object of header list must contain a value field
 * corresponding to item field containg the data
 *
 * @param {collection} itemList :
 *              collection of data object used to fill ":items" prop in datatable component
 *
 * @param {collection} headerList :
 *              collection of object used to fill ":headers" prop in datatable component
 */
Utils.createSheetXlsxFromDatatable = function (itemList, headerList) {
  let excel_headers = _.map(headerList, "text");
  let headers_values = _.map(headerList, "value");
  let excel_data = [];
  _.forEach(itemList, function (element) {
    let excel_row_data = [];
    _.forEach(headers_values, function (value) {
      let data = null;
      if (typeof element[value] === "string") {
        data = Utils.decodeHtmlString(element[value]);
      } else {
        data = element[value];
      }
      excel_row_data.push(data);
    });
    excel_data.push(excel_row_data);
  });
  excel_data.sort(sortArrayOfArrayByFirstColumn);
  let excel_table = _.concat([excel_headers], excel_data);
  let excel_sheet = XLSX.utils.aoa_to_sheet(excel_table);
  let end_header_cell_address = XLSX.utils.encode_cell({
    r: 1,
    c: excel_headers.length - 1
  });
  excel_sheet["!autofilter"] = {ref: `A1:${end_header_cell_address}`};
  return excel_sheet;
};

/**
 * Generate Xlsx file with one sheet containing exactly the same table that datatable
 * sorted by first column.
 *
 * WARNING : each object of header list must contain a value field
 * corresponding to item field containg the data
 *
 * @param {collection} itemList :
 *              collection of used data object to fill ":items" prop in datatable component
 *
 * @param {collection} headerList :
 *              collection of used object to fill ":headers" prop in datatable component
 *
 * @param {object} workbookProps :
 *              object containing workbook prop like title, creation date...
 *              (list of props on: https://github.com/sheetjs/js-xlsx#workbook-file-properties)
 *
 * @param {string} sheetTitle : title of sheet containing data
 *
 * @param {string} fileTitle : title of file download by user
 */
Utils.writeXlsxFromDatatableData = function (
  itemList,
  headerList,
  workbookProps,
  sheetTitle,
  fileTitle
) {
  let excel_sheet = Utils.createSheetXlsxFromDatatable(itemList, headerList);
  let workbook = XLSX.utils.book_new();
  workbook.props = workbookProps;
  XLSX.utils.book_append_sheet(workbook, excel_sheet, sheetTitle);
  XLSX.writeFile(workbook, fileTitle);
};

/**
 * Generate Xlsx file with one sheet containing exactly the same table that datatable
 * sorted by first column.
 *
 * WARNING : each object of header list must contain a value field
 * corresponding to item field containg the data
 *
 * @param {object} datatableRefs :
 *              reference vue of used datatable to create the same table in excel
 *
 * @param {object} workbookProps :
 *              object containing workbook prop like title, creation date...
 *              (list of props on: https://github.com/sheetjs/js-xlsx#workbook-file-properties)
 *
 * @param {string} sheetTitle : title of sheet containing data
 *
 * @param {string} fileTitle : title of file download by user
 */
Utils.writeXlsxFromDatatableRef = function (
  datatableRefs,
  workbookProps,
  sheetTitle,
  fileTitle
) {
  let itemList = datatableRefs._props.items;
  let headerList = datatableRefs._props.headers;
  Utils.writeXlsxFromDatatableData(
    itemList,
    headerList,
    workbookProps,
    sheetTitle,
    fileTitle
  );
};

/**
 * Round a number with X precision
 * @param {number} numberToRound number to round
 * @param {number} precision 2 by default
 * @return {number}
 */
Utils.roundNumber = function (numberToRound, precision = 2) {
  return _.round(numberToRound, precision);
};


/**
 * Télécharge le fichier spécifié
 * @param data
 * @param fileName
 * @param type
 */
Utils.downloadFile = function (data, fileName, type) {
  const url = window.URL.createObjectURL(new Blob([s2ab(atob(data))], {type: type}));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};

function s2ab(s) {
  let buf = new ArrayBuffer(s.length);
  let view = new Uint8Array(buf);
  for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
  return buf;
}

function sortArrayOfArrayByFirstColumn(a, b) {
  if (a[0] === b[0]) {
    return 0;
  } else {
    return a[0] < b[0] ? -1 : 1;
  }
}

Utils.s2ab = function (s) {
  let buf = new ArrayBuffer(s.length);
  let view = new Uint8Array(buf);
  for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
  return buf;
};

/**
 * Télécharge le fichier spécifié
 * @param data
 * @param fileName
 * @param type
 */
Utils.downloadFile = function (data, fileName, type) {
  const url = window.URL.createObjectURL(new Blob([Utils.s2ab(atob(data))], {type: type}));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};

Utils.decodeHtmlString = function (str) {
  return str.replace(/&#(\d+);/g, function (match, dec) {
    return String.fromCharCode(dec);
  });
};

/**
 * Formatage d'une liste de chaînes de caractères en français.
 * @param liste
 * @returns {str}
 */
Utils.formatListToFrench = function (liste) {
  if (liste.length === 0) {
    return "";
  }
  if (liste.length === 1) {
    return liste[0];
  }
  let dernier_item = liste.pop();
  let liste_as_string = liste.join(', ');
  return liste_as_string + " et " + dernier_item;
};

export default Utils;

/**
 * Renvoie l'année sous forme de date
 * @param year
 * @returns {str}
 */
Utils.yearToDate = function (year) {
  return year + "-01-01";
};

/**
 * Renvoie uniquement l'année de la date passée en paramètre
 * @param date
 * @returns {str}
 */
Utils.dateToYear = function (date) {
  return date.split("-")[0];
};

/**
 * Renvoie la liste d'erreurs après modification du format des dates
 * @param {errors} errors
 * @returns {Array[string]} une liste d'erreurs avec les dates bien formatées
 */
Utils.formatError = function (errors) {
  if (_.isNil(errors)) return null;
  let clonedErrors = _.cloneDeep(errors)
  const errorsFormatees = [];
  const regex = /\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])*/g
  clonedErrors.forEach(error => {
    let datesNonFormatees = error.match(regex);
    let error_formatee = error;
    let dateFormatee;
    if (datesNonFormatees) {
      datesNonFormatees.forEach(dateNonFormatee => {
        dateFormatee = Utils.formatDateStringIsoToFr(dateNonFormatee);
        error_formatee = error_formatee.replace(dateNonFormatee, dateFormatee);
      })
    }
    errorsFormatees.push(error_formatee);
  })
  return errorsFormatees;
};

/*
  Aplatir les family pour mettre les parents et les enfants au même niveau
  Exemple:
  // family
  [
      {
          "nom": "Callie",
          "children": [
              {
                  "nom": "CalamArcade",
                  "children": [],
              },
          ],
      },
      {
          "nom": "Marie",
          "children": [],
      },
  ]

  // Flatten family
  [
      {
          "nom": "Callie",
          "children": [
              {
                  "nom": "CalamArcade",
                  "children": [],
              },
          ],
      },
      {
          "nom": "CalamArcade",
          "children": [],
      },
      {
          "nom": "Marie",
          "children": [],
      },
  ]
*/
Utils.flattenFamily = function (family, children="children") {
  const cloned_family = _.cloneDeep(family);
  const flatten_family = [];
  cloned_family.forEach(individual => {
    flatten_family.push(individual);
    individual[children].forEach(child => {
      flatten_family.push(child);
    });
  });
  return flatten_family;
};
