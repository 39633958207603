<!-- VUETIFY2 - OK -->
<template>
    <base-layout headerSize="50px" datatableExcludedSizeDefault="300px">
        <template slot="header">
            <h1>Répartition des intérêts aux parts sociales</h1>
        </template>
        <template v-slot:main="{ datatableSize }">
            <loading-screen ref="loadingComponent"/>
            <v-container fluid>
                <v-row justify="space-between">
                    <v-col md="5" lg="5" xl="2">
                        <v-text-field v-model="exercice.libelle" :disabled="true" label="Exercice"/>
                    </v-col>
                    <v-col md="5" lg="5" xl="3">
                        <v-formatted-number-text-field
                            v-model="exercice.cap_exercice.montant_global_interets_part_sociale"
                            prefixValue="€" :reverseValue="true" :disabledValue="true"
                            labelValue="Montant des intérêts aux parts sociales"/>
                    </v-col>
                    <v-col md="5" lg="5" xl="2">
                        <v-formatted-number-text-field
                            v-model="tauxFiscalite.chargesSociales"
                            prefixValue="%"
                            :reverseValue="true" :disabledValue="true"
                            labelValue="Taux de charges sociales"/>
                    </v-col>
                    <v-col md="5" lg="5" xl="3">
                        <v-formatted-number-text-field
                            v-model="tauxFiscalite.prelevementForfaitaire"
                            prefixValue="%" :reverseValue="true" :disabledValue="true"
                            labelValue="Taux de prélèvement forfaitaire"/>
                    </v-col>
                </v-row>
                <v-datatable-repartition-cap :repartitionsList="repartitions"
                                             :isRepartitionValide="exercice.cap_exercice.is_repartition_valide"
                                             :message_detail_extra_mouvements_after_exercice="message_detail_extra_mouvements_after_exercice"
                                             ref="datatablerepartition"
                                             :height="datatableSize"
                />
            </v-container>
        </template>
        <template slot="footer">
            <v-row dense>

                <!-- Footer gauche -->
                <!-- Petit bouton -->
                <v-col md="3" lg="3" xl="2">
                    <v-btn dark color="primary" outlined @click="downloadExcel" block>
                        <v-icon dark left>{{ "$vuetify.icons.excel" }}</v-icon>
                        Télécharger
                    </v-btn>
                </v-col>
                <!-- Petit bouton -->
                <v-col md="3" lg="3" xl="2">
                    <detail-repartition-cap v-if="!exercice.cap_exercice.reprise" :explain="explain"/>
                </v-col>
                <!-- Petit bouton -->
                <v-col md="3" lg="3" xl="2" v-if="exercice.cap_exercice.is_repartition_valide">
                    <v-popup-asynchrone
                        documentsNamePlural="fiches individuelles"
                        :isDocumentGenderFeminine="true"
                        documentsFullNamePlural="fiches individuelles capital"
                        documentPdfName="fiches_individuelles_capital"
                        :getStateDocuments="getStateFichesIndividuelles"
                        :generateDocuments="generateFichesIndividuelles"
                        :downloadDocuments="downloadFichesIndividuelles"
                        :showDateReferenceDernierTraitement="true"
                    />
                </v-col>

                <v-spacer/>

                <!-- Footer droite -->
                <template v-if="exercice.cap_exercice.is_repartition_valide">
                    <!-- Répartition validée -->
                    <!-- Petit bouton -->
                    <v-col md="3" lg="3" xl="2">
                        <v-btn :to="{name: 'cap-exercices'}" block>Retour</v-btn>
                    </v-col>
                </template>
                <template v-else>
                    <!-- Répartition non-validée -->
                    <!-- Petit bouton -->
                    <v-col md="3" lg="3" xl="2">
                        <v-btn :to="{name: 'cap-exercices'}" block>Annuler</v-btn>
                    </v-col>
                    <!-- Petit bouton -->
                    <v-col md="3" lg="3" xl="2">
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-custom-confirmation-dialog :message="confirmationMessage"
                                                              openButtonMessage="Valider"
                                                              title="Valider la répartition des intérêts aux parts sociales"
                                                              :isIcon="false"
                                                              :activator="on"
                                                              @action-confirmee="saveRepartitions()"
                                />
                            </template>
                            <span>{{messageValidationExercice}}</span>
                        </v-tooltip>
                    </v-col>
                </template>

            </v-row>
        </template>
    </base-layout>
</template>

<script>
    import internalApi from "@/api/internalApi";
    import Utils from "@/utils";
    import XLSX from "xlsx";
    import VFormattedNumberTextField from "@/components/VFormattedNumberTextField.vue";
    import VCustomConfirmationDialog from "@/components/VCustomConfirmationDialog.vue";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import constantes from "@/utils/constantes";
    import VDatatableRepartitionCap from "@/components/repartitionCap/VDatatableRepartitionCap";
    import DetailRepartitionCap from "@/components/repartitionCap/VDetailRepartitionCap";
    import VPopupAsynchrone from "@/components/VPopupAsynchrone.vue";
    import BaseLayout from "@/components/BaseLayout";

    /**
     * Vue affichant une table contenant, pour un exercice donné, la répartition des intérêts
     * aux parts sociales
     * @displayName Capital - RepartitionCap
     */
    export default {
        components: {
            BaseLayout,
            DetailRepartitionCap,
            VDatatableRepartitionCap,
            VFormattedNumberTextField,
            VCustomConfirmationDialog,
            VPopupAsynchrone,
            loadingScreen,
        },
        data() {
            return {
                dialogDetail: false,
                dialogSave: false,
                repartitions: [],
                categoryColumns: [],
                associeRows: [],
                headers: [],
                explain: {message_detail_extra_mouvements_after_exercice: undefined},
                confirmationMessage: "<br/>Confirmez-vous la validation de cette répartition des intérêts ?<br/><br/>Une fois l'exercice validé les opérations rattachées seront figées :<ul> <li>Mouvements;</li><li>Répartition des intérêts aux parts sociales;</li><li>Paramétrage de l'exercice.</li></ul>",
                messageValidationExercice: "Une fois validé, l’exercice sera verrouillé. Aucune création, suppression et modification ne pourra plus être enregistrée sur la période couverte par l’exercice.",
                tauxFiscalite: {
                    chargesSociales: null,
                    prelevementForfaitaire: null,
                },
                exercice: {
                    libelle: "",
                    cap_exercice: {},
                },
                selectedAction: undefined,
            };
        },
        mounted() {
            const {id} = this.$route.params;
            this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);
            this.getCapExerciceById(id).then(result => {

                internalApi.capital.getCapitalADate(this.exercice.date_fin)
                    .then(res => {
                        this.confirmationMessage += "<br/>Capital de l'entreprise au terme de l'exercice : <b>" + this.$options.filters.roundEuroFilter(res.capital_a_date) + "</b>";
                    });

                return new Promise((resolve, reject) => {

                    let promises = [];

                    let promise_taux_fiscalite = this.getTauxFiscaliteByDate();
                    promises.push(promise_taux_fiscalite);

                    let promise_interets;
                    if (this.exercice.cap_exercice.is_repartition_valide) {
                        const {id} = this.exercice;
                        promise_interets = internalApi.capExercice.repartition.getRepartitionCapByExerciceId(id)
                            .then(res => {
                                this.explain = res.explain;
                                this.repartitions = res.data;
                            });
                    } else {
                        promise_interets = internalApi.capExercice.repartition.getPropositionRepartitionCapByExerciceId(
                                this.exercice.id
                            ).then(res => {
                                this.explain = res.explain;
                                this.repartitions = res.data;
                            });
                    }
                    promises.push(promise_interets);

                    Promise.all(promises).then(res => {
                        resolve(res);
                    }).catch(error => {
                        reject(error);
                    });
                });
            })
            .finally(() => {
                this.$refs.loadingComponent.resetLoading();
            });


            // categoryColum, associeRow et header sont valorisés directement par le composant fils
            // on observe ces variable directement pour éviter de dupliquer du code
            this.$watch(() => this.$refs.datatablerepartition.categoryColumns, (value) => {
                this.categoryColumns = value;
            });
            this.$watch(() => this.$refs.datatablerepartition.associeRows, (value) => {
                this.associeRows = value;
            });
            this.$watch(() => this.$refs.datatablerepartition.headers, (value) => {
                this.headers = value;
            });
        },
        computed: {
            message_detail_extra_mouvements_after_exercice() {
                return _.isNil(this.explain) ? undefined : this.explain.message_detail_extra_mouvements_after_exercice;
            },
        },
        methods: {
            getCapExerciceById(cap_exercice_id) {
                return internalApi.capExercice.getById(cap_exercice_id).then(
                    result => {
                        this.exercice = result;
                    }
                );
            },
            /**
             *  Récupère les taux de fiscalité valables à la date de fin de l'exercice
             */
            getTauxFiscaliteByDate() {

                this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);

                const promiseCsgCrds = internalApi.tauxFiscalite.chargesSociales.getValueAtDate(this.exercice.date_fin)
                .then(result => {
                    this.tauxFiscalite.chargesSociales = result.taux;
                })
                .catch(error => {
                    console.error(error);
                });

                const promiseForfaitSocialStandard = internalApi.tauxFiscalite.prelevementForfaitaire.getValueAtDate(this.exercice.date_fin)
                .then(result => {
                    this.tauxFiscalite.prelevementForfaitaire = result.taux;
                })
                .catch(error => {
                    console.error(error);
                });

                return Promise.all([promiseCsgCrds, promiseForfaitSocialStandard]).finally(() => {
                    this.$refs.loadingComponent.resetLoading();
                });
            },
            /**
             * Post API call to save repartition in db, update capExercice state in store
             * and then navigate to capExercice list screen
             */
            saveRepartitions() {
                this.$refs.loadingComponent.setLoading("Enregistrement en cours");
                internalApi.capExercice.repartition
                    .postRepartitionCapByExerciceId(this.exercice.id)
                    .then(res => {
                        this.$router.push({name: "cap-exercices"});
                        this.$refs.loadingComponent.resetLoading();
                    })
                    .catch(err => {
                        this.$refs.loadingComponent.resetLoading();
                    });
            },
            /**
             * Renvoie la liste des lignes de la datatable pour l'affichage dans un fichier excel
             * @returns {Array}
             */
            getDataExcel() {
                let prepare_data = this.buildHeadersForExcel();
                let rows = prepare_data.headers;

                return _.concat(rows, this.buildRowsForExcel(prepare_data.categories));
            },
            /**
             * Construit les lignes de la datatable pour le excel
             * @returns {{headers: [], categories: Array}}
             */
            buildHeadersForExcel() {
                const EMPTY_CELL_VALUE = "";

                let header_1 = ["Associé", "Numéro de tiers"];
                let header_2 = [EMPTY_CELL_VALUE, EMPTY_CELL_VALUE];
                let categories_labels = [];

                for (let category of this.categoryColumns) {
                    categories_labels.push(category.label);
                    header_1 = _.concat(header_1, [
                        category.label,
                        EMPTY_CELL_VALUE,
                        EMPTY_CELL_VALUE,
                        EMPTY_CELL_VALUE,
                        EMPTY_CELL_VALUE,
                        EMPTY_CELL_VALUE,
                    ]);
                    header_2 = _.concat(header_2, [
                        "Montant brut",
                        "Nombre parts",
                        "Charges sociales",
                        "Montant net",
                        "Prélèvement forfaitaire",
                        "Montant à payer"
                    ]);
                }
                header_1 = _.concat(header_1, ["Total brut", "Charges sociales", "Total net", "Total prélèvements forfaitaires", "Total à payer"]);

                return {headers: [header_1, header_2], categories: categories_labels};
            },
            /**
             * Met en forme les lignes de la datatable pour le excel
             * @param categories_labels
             * @returns {Array}
             */
            buildRowsForExcel(categories_labels) {

                function sortRowsAlphabetically(row1, row2) {
                    if (row1[0] < row2[0]) {
                        return -1;
                    } else if (row1[0] > row2[0]) {
                        return 1
                    } else {
                        return 0;
                    }
                }

                const EMPTY_CELL_VALUE = "";

                let rows = [];
                const default_data = {
                    montant: EMPTY_CELL_VALUE,
                    montant_net: EMPTY_CELL_VALUE,
                    nb_parts: EMPTY_CELL_VALUE,
                    prelevement_forfaitaire: EMPTY_CELL_VALUE,
                    charges_sociales: EMPTY_CELL_VALUE,
                    montant_a_payer: EMPTY_CELL_VALUE
                };

                for (let row_ass of this.associeRows) {
                    let row = [row_ass.label, row_ass.numero_tiers];
                    for (let categ of categories_labels) {
                        let data = _.get(row_ass, categ, default_data);
                        row = _.concat(row, [
                            data.montant,
                            data.nb_parts,
                            data.charges_sociales,
                            data.montant_net,
                            data.prelevement_forfaitaire,
                            data.montant_a_payer
                        ]);
                    }
                    row = _.concat(row, [
                        row_ass.total,
                        row_ass.total_charges_sociales,
                        row_ass.total_net,
                        row_ass.total_prelevement_forfaitaire,
                        row_ass.total_a_payer
                    ]);
                    rows.push(row);
                }

                rows.sort(sortRowsAlphabetically);

                return rows;
            },
            /**
             * Helper pour télécharger le fichier Excel
             */
            downloadExcel: function () {
                const ws = XLSX.utils.aoa_to_sheet(this.getDataExcel());
                const wb = XLSX.utils.book_new();
                wb.props = {
                    Title: "Répartition intérêts",
                    Subject: "Répartition intérêts aux parts sociales",
                    Author: "eZIScop",
                    CreatedDate: Date.now()
                };
                XLSX.utils.book_append_sheet(wb, ws, "Répartition");
                XLSX.writeFile(wb, "repartition_interets.xlsx");
            },

            getStateFichesIndividuelles(){
                return internalApi.capExercice.repartition.getStateFichesIndividuelles(this.exercice.id);
            },
            /**
             * Lance la génération des fiches individuelles
             */
            generateFichesIndividuelles(){
                return internalApi.capExercice.repartition.generateFichesIndividuelles(this.exercice.id);
            },
            /** Télécharge les fiches individuelles */
            downloadFichesIndividuelles(){
                return internalApi.capExercice.repartition.getFichesIndividuelles(this.exercice.id);
            },
        }
    };
</script>
